<template>
  <div>
    <div v-if="status == 'success'" class="card mx-3 my-0 py-5" :class="$style.container">
      <div class="text-dark text-center font-size-24">
        <a-icon style="fontSize: 68px" class="text-success mb-4" type="check-circle" /> <br/>
        <strong> Email anda telah di konfirmasi. </strong> Silahkan login kembali ke aplikasi. <br />
        <span class="text-secondary" style="fontSize: 13px">NOTE: Anda dapat menutup halaman ini.</span>
      </div>
    </div>
     <div v-if="status == 'fail'" class="card my-0 mx-3 py-5" :class="$style.container">
      <div class="text-dark text-center font-size-24">
        <a-icon style="fontSize: 68px" class="text-danger mb-4" type="close-circle" /> <br/>
        <strong> Kesalahan dalam konfirmasi email </strong> Harap ulangi proses verifikasi di aplikasi. <br />
        <span class="text-secondary" style="fontSize: 13px">NOTE: Anda dapat menutup halaman ini.</span>
      </div>
    </div>
  </div>
</template>
<script>
import { confirmEmail } from '@/services/axios/api/pasien'
export default {
  name: 'ConfirmEmail',
  mounted () {
    this.status = ''
    this.verify()
  },
  data: function () {
    return {
      status: '',
      form: this.$form.createForm(this),
    }
  },
  methods: {
    async verify () {
      const res = await confirmEmail(this.$route.params.id)
      if (res) {
        console.log(res)
        if (res.data) {
          this.status = 'success'
        }
      } else {
        this.status = 'fail'
      }
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
